@import "../../App";

.accordion {
    .accordion-item {
      border: 1px solid #ddd;
      border-radius: 4px;
      margin-bottom: 10px;
  
      .accordion-header {
        background: #fafafa;
        border: none;
        padding: 10px 20px;
        width: 100%;
        text-align: left;
        cursor: pointer;
        transition: background-color 0.3s ease;
  
        &:hover, &:focus {
          background-color: #e2e2e2;
        }
  
        &:after {
          content: '\002B';
          float: right;
          font-family: 'sans-serif';
        }
      }

      .accordion-header-active {
        background: #fafafa;
        border: none;
        font-weight: 600;
        padding: 10px 20px;
        width: 100%;
        text-align: left;
        cursor: pointer;
        transition: background-color 0.3s ease;
  
        &:hover, &:focus {
          background-color: #e2e2e2;
        }
  
        &:after {
          content: '\002D';
          float: right;
          font-family: 'sans-serif';
        }
      }
  
      .accordion-body {
        padding: 0 20px 20px;
        display: none;
  
        p {
          margin: 0;
        }
      }

      .active {
        display: block; 
      }
    }
  }  