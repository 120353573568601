@import "mixins";

html {
  /* root font size - easy way to control all other properties with rem units */
  /* The default browser font size is 16px so 62.5% gives us 10px which will make
     the rest of our rem calculations easier */
  font-size: 62.5%;

  //Remember that the order of the media queries makes a difference because if more
  //than one happens to be true the last one will be used.

  @include respond(tab-land) {
    //1rem = 9px, 9/16 = 56.25
    font-size: 56.25%;
  }

  @include respond(tab-port) {
    //1rem = 8px, 8/16 = 50%
    font-size: 50%;
  }

  //@include respond(phone) {
  //  font-size: 45%;
  //}
}

body {
  box-sizing: border-box; /* border-box will include border + padding into the width/height */
}

input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}