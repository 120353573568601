/*
Media Queries

0 - 600       Phone
600 - 900     Tablet portrait
900 - 1200    Tablet landscape
> 1200        Normal desktop
*/

/*
$breakpoint: phone | tab-port | tab-land

We use em's so that the media queries can respond to the browser font size
which the user can change any time.

1em = 16px <- default browser font size
*/
@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    //680px
    @media (max-width: 42.5em) {
      @content;
    }
  }
  @if $breakpoint == tab-port {
    //900px
    @media (max-width: 57.25em) {
      @content;
    }
  }
  @if $breakpoint == tab-land {
    //1200px
    @media (max-width: 75em) {
      @content;
    }
  }
}
