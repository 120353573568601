@import "../../App";

.vineyard-layout {
  margin-left: 8rem;
  margin-top: 5rem;
  margin-bottom: 5rem;
  max-width: 815px;

  @include respond(phone) {
    margin: 25px;
  }
}

.vineyard-field-name {
  width: 14rem;
}

.person-of-interest-role {
  font-style: italic;
}