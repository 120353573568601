$primary: #0C1E31;
$button-color: #184472;
$button-disabled-color: #ccc;
$color-input-border: #aaa;
$color-input-error: #B3261E;
$color-warning: #e8a057;
$white: #fff;
$footer: #F1F2F4;
$footer-color: #424242;
$footer-link-color: #215d9c;
$color-link: #215d9c;
$color-placeholder: #888;