@import "styles/base";
@import "styles/mixins";
@import "styles/colors";
@import "styles/typography";
@import "styles/utility";
@import "styles/button";
@import "styles/input";
@import "styles/form";
@import "styles/pagination";

header {
  background: $primary;
  color: $white;
}

a {
  color: $color-link;
}

a:hover {
  color: $color-link;
}