@import "../../App";

.about-layout {
  padding: 3rem 8rem;
  max-width: 1100px;

  @include respond(phone) {
    padding: 25px;
  }
}

.sponsor-layout {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(40rem, 1fr));
  align-items: center;
  padding-left: 2rem;

  @include respond(phone) {
    //grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    padding-left: 10px
  }
}

.sponsor-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  @include respond(phone) {
    width: 300px;
    height: 150px;
  }
  height: 212px;

  &-smaller {
    width: 300px;
    @include respond(phone) {
      width: 250px;
    }
  }
}

.contest-sponsor-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 160px; // adjusted height

  @include respond(phone) {
    width: 300px; // remains the same on phone
    height: 150px; // unchanged
  }

  &-smaller {
    width: 225px; // adjusted width
    @include respond(phone) {
      width: 250px; // unchanged on phone
    }
  }
}