@mixin headingFont {
  font-family: Alegreya Sans, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
}

@mixin description-text {
  font-family: "Alegreya", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2rem;
  line-height: 160%;
  color: #212121;
}

@mixin input-text {
  font-family: 'Alegreya Sans', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #212121;
}

@mixin form-field-sub-text {
  font-family: 'Alegreya Sans', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.4px;
}

@mixin button-text {
  font-family: 'Alegreya Sans', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 2.4rem;
  letter-spacing: 0.5px;
}

.old-vines-registry {
  .heading-font {
    @include headingFont;

    &:visited {
      color: white;
    }
    
    &-bold {
      @include headingFont;
      font-weight: 700;

      &-fixed-scale {
        @include headingFont;
        font-size: 18px;
      }
    }
  }

  .menu-heading {
    &:visited {
      color: white;
    }

    color: white;
    text-decoration: none;
  }

  .menu-heading:hover {
        color: #e6e6e6;
    }

  .alegreya-heading-white {
      font-family: 'Alegreya Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 56px;
      color: white;
      line-height: 5rem;
      margin-top: 24px;
      /* or 100% */
  
      filter: brightness(100%); 
      display: block;
      padding: 3rem 8rem;
      letter-spacing: -0.5px;
      font-feature-settings: 'ss01' on;
    }

  .cinzel-heading {
    font-family: 'Cinzel Decorative', cursive;
    font-style: normal;
    font-weight: 400;
    font-size: 5rem;
    line-height: 5rem;
    margin-top: 24px;
    /* or 100% */

    display: block;
    text-align: center;
    letter-spacing: -1.5px;
    font-feature-settings: 'ss01' on;
  }

  .competition-heading {
    font-family: 'Alegreya', cursive;
    font-style: normal;
    font-weight: 400;
    font-size: 4rem;
    line-height: 3rem;
    /* or 100% */

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -1.5px;
    font-feature-settings: 'ss01' on;
  }

  .input-text {
    @include input-text;
  }

  .input-text-fixed-scale {
    @include input-text;
    font-size: 18px;
  }

  .description-text-center {
    @include description-text;
    text-align: center;
  }

  .description-text-center-stats {
    @include description-text;
    text-align: center;
  }

  .description-text-center-contest {
    @include description-text;
    text-align: center;
  }

  .description-text {
    @include description-text;

    &-link {
      @include description-text;
      text-decoration: underline;
      cursor: pointer;
      color: #215d9c;
    }
  }

  .warning-text {
    @include description-text;
    color: $color-warning;
    font-size: medium;
  }

  .button-text {
    @include button-text;
  }

  .title-small {
    font-family: 'Alegreya Sans', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 160%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #212121;
  }

  .title-medium {
    font-family: 'Alegreya Sans', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 3.4rem;
    line-height: 125%;
    letter-spacing: -0.5px;
    font-feature-settings: 'pnum' on, 'lnum' on;

    @include respond(phone) {
      font-size: 24px;
    }
  }

  .title-large {
    font-family: 'Alegreya Sans', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 4.2rem;
    line-height: 125%;
    letter-spacing: -0.5px;
    font-feature-settings: 'pnum' on, 'lnum' on;

    @include respond(phone) {
      font-size: 24px;
    }
  }

  .sub-headline {
    font-family: 'Alegreya Sans', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 2.1rem;
    letter-spacing: -0.5px;
    font-feature-settings: 'pnum' on, 'lnum' on;
  }

  .search-link {
    text-decoration: underline;
    font-family: "Alegreya Sans", serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 160%;
    color: $color-link;
    cursor: pointer;
  }

  .field-name {
    font-family: 'Alegreya Sans SC', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 191%;
    text-transform: lowercase;
    color: #212121;
  }

  .field-value {
    font-family: 'Alegreya', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 2.4rem;
    line-height: 160%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #212121;
  }

  .form-label {
    font-family: 'Alegreya Sans', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 160%;
    color: #212121;

    &-error {
      color: $color-input-error;
    }
  }

  .form-fields-sub-text {
    color: #616161;
    padding: 4px 16px 0 4px;
    @include form-field-sub-text;

    &-link {
      color: $color-link;
      text-decoration: underline;
    }
  }

  .checkbox-label-text {
    font-family: 'Alegreya Sans', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.2px;
  }

  .submit-error-heading {
    font-family: 'Alegreya Sans', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 160%;
    color: $color-input-error
  }
}