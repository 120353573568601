@import "colors";

.old-vines-registry {
  input[type=text],input[type=password],textarea {
    display: block;
    border: 1px solid $color-input-border;
    border-radius: 3px;
    padding: 0.5rem 1rem;
    height: 48px;

    &:focus {
      outline: 1px solid #adb4da;
    }
  }

  .form-control,.form-select {
    border: 1px solid $color-input-border;
    &:focus {
      outline: 1px solid #adb4da;
      border: 1px solid $color-input-border;
      box-shadow: none;
    }
  }

  textarea {
    height: 70px;
  }

  .input-height {
    height: 48px;
  }

  select {
    display: inline-block;
    border: 1px solid $color-input-border;
    border-radius: 3px;
    padding: 0.5rem 1rem;
    height: 48px;

    &:focus {
      outline: 1px solid #adb4da;
    }
  }

  .form-check {
    .form-check-input {
      width: 16px;
      height: 16px;
    }
    .form-check-label {
      font-size: 14px;
    }
  }
}