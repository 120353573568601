.u-ml-small {
  margin-left: 8px;
}

.u-ml-medium {
  margin-left: 16px;
}

.u-ml-large {
  margin-left: 32px;
}

.u-ml-xlarge {
  margin-left: 64px;
}

.u-mr-small {
  margin-right: 8px;
}

.u-mr-medium {
  margin-right: 16px;
}

.u-mr-large {
  margin-right: 32px;
}

.u-mr-xlarge {
  margin-right: 64px;
}

.u-mt-small {
  margin-top: 8px;
}

.u-mt-medium {
  margin-top: 16px;
}

.u-mt-large {
  margin-top: 32px;
}

.u-mt-xlarge {
  margin-top: 64px;
}
