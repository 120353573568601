.old-vines-registry {
  .input-error {
    color: $color-input-error;

    @include form-field-sub-text;
  }

  .input-field-error {
    border-color: $color-input-error !important;
  }

  .field-spacing {
    margin-top: 16px;
  }
}