@import "../../styles/mixins";
@import "../../styles/colors";

.select-label {
  display: inline-block;
  width: 220px;
  font-size: 1.9rem;
  color: $color-placeholder;
}

.search-box {
  display: flex;
  align-items: stretch;
  /*justify-content: space-between;*/
  gap: 10px;

  @include respond(phone) {
    flex-direction: column;
    margin-left: 0px;
  }
}

.search-combo {
  width: 180px;
  
  @include respond(phone) {
    width: 100%;
  }
}

.search-text {
  color: gray !important; /* Default color for unselected options */

  &.selected {
    color: black !important; /* Default color for unselected options */
  }
}

.search-item {
  align-items: stretch;
}

.search-command {
  width: 55px;
}