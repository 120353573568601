@import "../../App.scss";

/* By default, assume desktop */
.desktop-only {
  display: block;
}
.mobile-only {
  display: none;
}

.image-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 400px;
}

/* Mobile layout */
@media (max-width: 680px) {
  .desktop-only {
    display: none;
  }
  .mobile-only {
    display: block;
  }

  .image-container {
    width: 250px;
  }
  
}

.app-footer {
  background: $footer;

  padding: 4rem 8.4rem;
  @include respond(phone) {
    padding: 4rem 30px
  }

  font-family: 'Alegreya Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 135%;

  color: $footer-color;

  &-link {
    color: $color-link;
    line-height: 160%;
    font-size: 18px;
    font-weight: 500;
  }

  &-updated {
    font-size: 1.4rem;
  }
}

.navigation-panel {
  display: flex;
  align-items: center;
  @include respond(phone) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.bullet {
  @include respond(phone) {
    display: none;
  }
  width: 4px;
  height: 4px;
  border-radius: 2px;
  margin: 5px 10px;
  background: #757575;
}

.footer-flex {
  display: flex;
  justify-content: space-between;
  gap: 50px;
  @include respond(phone) {
    gap: 0px;
  }
}

.award-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 258px;
  height: 152px;
  @include respond(phone) {
    width: 200px;
    height: 120px;
  }
}

