@import "colors";

.old-vines-registry {
  //bootstrap button style
  .button-background {
    background: $button-color;
  }

  .button {
    display: inline-block;
    height: 48px;
    border: none;
    border-radius: 3px;
    background: $button-color;
    color: $white;
    font-size: 1.5rem;
    padding: 0.5rem 1.5rem;
    cursor: pointer;
    transition: all 0.2s;

    @include button-text;

    &:link,
    &:visited {
      text-decoration: none;
    }

    &:hover {
      background: lighten($button-color, 3%);
    }

    &:active {
      background: darken($button-color, 3%);
    }

    &[disabled] {
      cursor: none;
      background: $button-disabled-color;
      pointer-events: none;
    }
  }
}