@import "../../App";

.submit-layout {
  padding: 3rem 8rem;
  max-width: 1100px;

  @include respond(phone) {
    padding: 25px;
  }
}

.old-vines-registry {
  .notes {
    height: 140px;
  }

  .name-input {
    width: 292px;

    @include respond(phone) {
      width: 100%
    }
  }

  .year-input {
    width: 185px;
  }
}