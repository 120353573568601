.menu {
  display: flex;
  justify-content: right;
  height: 0;
  transition: all 400ms ease-in-out;
  overflow: hidden;

  &-expanded {
    height: 218px;
    padding-right: 20px;
    padding-bottom: 20px;
  }

  &-item {
    transition: all 200ms;
    cursor: pointer;

    &:hover {
      color: darken(white, 10%);
    }
  }
}