.old-vines-registry {
  ul.pagination {
    --bs-pagination-padding-x: 12px;
    --bs-pagination-padding-y: 2px;
    --bs-pagination-font-size: 20px;
    --bs-pagination-color: #215d9c;
    --bs-pagination-disabled-color: #bdbdbd;
    margin-bottom: 0;
  }
}

