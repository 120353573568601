@import "../../App";

.content-panel {
  padding: 4rem 8rem;
  @include respond(phone) {
    padding: 16px;
  }
}

.search-input {
  width: 100%;
  @include respond(phone) {
    width: 220px;
  }
  max-width: 398px;
}

.search-button {
  min-width: 110px;
}

td {
  height: 64px;
}

.or-label {
  font-family: "Alegreya", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2rem;
  text-align: left;
}