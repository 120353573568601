@import "../../App";

.about-layout {
  padding: 3rem 8rem;
  max-width: 1100px;

  @include respond(phone) {
    padding: 25px;
  }
}

.sponsor-layout {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(40rem, 1fr));
  align-items: center;
  padding-left: 2rem;

  @include respond(phone) {
    //grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    padding-left: 10px
  }
}

.table-of-contents {
  
  .title {
    font-weight: bold;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      margin-bottom: 0px;
    }
  }
}

.navigationlink {
  text-decoration: underline!important;
  cursor: pointer;
  color: #215d9c!important;
}

.sponsor-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  @include respond(phone) {
    width: 300px;
    height: 150px;
  }
  height: 212px;

  &-smaller {
    width: 300px;
    @include respond(phone) {
      width: 250px;
    }
  }
}