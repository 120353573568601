@import "../../App";

.about-layout {
  padding: 3rem 8rem;
  max-width: 1100px;

  @include respond(phone) {
    padding: 25px;
  }
}

.sponsor-layout {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(40rem, 1fr));
  align-items: center;
  padding-left: 2rem;

  @include respond(phone) {
    //grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    padding-left: 10px
  }
}

.sponsor-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  @include respond(phone) {
    width: 300px;
    height: 150px;
  }
  height: 212px;

  &-smaller {
    width: 300px;
    @include respond(phone) {
      width: 250px;
    }
  }
}

/* Style the table itself */
table {
  border-collapse: collapse; /* Ensures that the border is clean and not doubled up */
  width: 100%; /* Adjust as necessary */
  table-layout: fixed; /* Optional: helps with consistent column sizing */
}

/* Style the table headers and cells */
th, td {
  border: 1px solid #ddd; /* Solid border with a light grey color */
  text-align: left; /* Aligns text to the left; change as needed */
  padding: 8px; /* Adjust padding for comfort */
  vertical-align: top;
}

/* Style the table rows for hover effect (optional) */
tr:hover {
  background-color: #f9f9f9; /* Light grey background on row hover */
}

/* Style the table headers */
th {
  background-color: #f0f0f0; /* Light grey background for headers */
  color: black; /* Text color for headers */
}
