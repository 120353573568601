@import "../../App";

.contest-layout {
  padding: 3rem 8rem;
  max-width: 1100px;

  @include respond(phone) {
    padding: 25px;
  }
}

li {
  margin-left: 20px;
}

.contest-rules {
  margin-left: 20px;
}