@import "../../styles/mixins";
@import "../../App.scss";

.picture-panel {
  padding: 100px 0;

  @include respond(phone) {
    padding-bottom: 80px;
    padding-top: 80px;
  }
}

.search-panel {
  display: flex;
  flex-direction: column;
  padding-left: 5rem;
  padding-right: 5rem;
  gap: 12px;

  @include respond(phone) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.search-bar {
  grid-column: 1; /* Place in column 2 */
  grid-row: 1;    /* Place in row 1 */
}


.search-options {
  grid-column: 1; /* Place in column 2 */
  grid-row: 3;    /* Place in row 3 */
}

.main-or-label {
  grid-column: 1; /* Place in column 1 */
  grid-row: 2;    /* Place in row 2 */
  font-family: "Alegreya", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2rem;
  text-align: center;
}

.search-container {
  display: grid;
  grid-template-columns: 1fr;    /* Single column layout */
  grid-auto-rows: auto;          /* Auto height for rows */
  align-items: center;           /* Center items vertically */
  width: 100%;
  max-width: 800px;              /* Optional: Restrict container width */
}

.competition-panel {
  background: $footer;
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 30rem;
  gap: 24px;

  @include respond(phone) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (max-width: 600px) {
  .search-container {
    grid-template-columns: 1fr; /* Single column layout */
  }

  .search-bar,
  .or-label,
  .search-options {
    grid-column: 1; /* All elements span one column */
    text-align: center; /* Center-align content */
  }
}